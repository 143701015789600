<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="600px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="任务名称">
        <el-input v-model="form.name" />
      </el-form-item>

      <el-form-item label="任务类型">
        <el-input v-model="form.type" />
      </el-form-item>

      <el-form-item label="随系统启动">
        <el-switch v-model="form.enabled" :active-value="1" />
      </el-form-item>

      <el-form-item label="定时启动">
        <el-input v-model="form.cron" /> 
      </el-form-item>

      <el-form-item label="描述">
        <el-input v-model="form.description" type="textarea" resize="none" :rows="3" />
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import _ from 'lodash';
export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {},
    }
  },

  methods: {
    async show(initForm, options = {}) {
      this.form = _.assign({}, initForm, initForm.config ? JSON.parse(initForm.config) : {});
      this.options = options;
      this.visible = true;
    },

    async confirmForm() {
      try {
        this.form.config = JSON.stringify(_.pick(this.form, ['mod', 'freq']))
        if(this.form.id) {
          await this.$api.execute('task.update', this.form.id, this.form);
        } else {
          await this.$api.execute('task.create', this.form);
        }
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        this.$message.error(e.message);
      }
    }
  }
}
</script>